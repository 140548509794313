























































import Vue from 'vue';
import { required, email, minLength } from 'vuelidate/lib/validators';
import * as toast from '@/shared/configs/toasts/toasts.config';

export default Vue.extend({
  data () {
    return {
      pendingRequest: false,
      showPassword: false,
      errorMessage: '',
      loginForm: {
        email: '',
        password: null
      }
    };
  },

  methods: {
    // ================================================================================
    // Events
    // ================================================================================

    onTogglePasswordVisibility (): void {
      this.showPassword = !this.showPassword;
    },

    onRegisterRequested (): void {
      this.$router.push({ name: 'Authentication:Register' });
    },

    async onLogin (): Promise<void> {
      if (this.pendingRequest) { return; }

      try {
        this.pendingRequest = true;
        this.errorMessage = '';
        await this.$store.dispatch('auth/login', this.loginForm);
        await this.$router.replace({ name: 'App:Dashboard' });
      } catch (e) {
        switch (e.code) {
          case 'auth/user-not-found':
            this.errorMessage = toast.messages.auth.login.error.userNotFound;
            break;
          case 'auth/wrong-password':
            this.errorMessage = toast.messages.auth.login.error.wrongPassword;
            break;
          case 'user-cannot-be-retrieved':
            this.errorMessage = '';
            break;
          default:
            this.errorMessage = toast.messages.common.error;
            break;
        }
      } finally {
        this.pendingRequest = false;
      }
    }
  },

  // ================================================================================
  // Validations
  // ================================================================================
  validations: {
    loginForm: {
      email: { required, email },
      password: { required, minLength: minLength(6) }
    }
  }
});
